import { loginOrSignUpCheckedEmailStorageKey } from "~/utils/loginSignup/loginSignup"
import { appPaths } from "~/utils/paths/paths"

export default defineNuxtRouteMiddleware((to, from) => {
  const checkedEmail = useCookie(loginOrSignUpCheckedEmailStorageKey)

  if (checkedEmail.value) {
    return true
  }

  return navigateTo(appPaths.loginSignup)
})
